/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { getControlType } from '../types';
import Tooltip from 'styleguide/components/Tooltip/Tooltip';
import CustomDimensionSelect from '../CustomDimensionSelect/CustomDimensionSelect';
import { Attribute as AttributeType, Value } from 'api/persephone/productTypes';
import tooltips from '../tooltips';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import Input from 'styleguide/components/Formik/Input/Input';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';
import Label from 'styleguide/components/Formik/Label/Label';
import { Field, useFormikContext } from 'formik';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import LinkLike from 'styleguide/components/Links/LinkLike';
import { KeyVal } from 'libs/utils/common-types';
import { getValueTitleByAttributeKeyAndValue } from 'libs/utils/printivity/quoterJson';
import PersephoneContext from 'contexts/PersephoneContextContainer/PersephoneContext';
import camelCase from 'lodash-es/camelCase';
import { clickField, clickTooltip } from 'api/gtm';
import debounce from 'lodash-es/debounce';

interface Props extends AttributeType {
  _key: string;
  placeholder: string;
  customDimension?: boolean;
  customDimensionFeet?: boolean;
  customDimensionRange?: number[];
  hideTooltips?: boolean;
  validate?: (value) => string;
}

const Attribute = ({ ...props }: Props) => {
  const quoteContext = React.useContext(QuoteContext);
  const persephoneContext = React.useContext(PersephoneContext);
  const { values, setFieldValue } = useFormikContext<KeyVal>();
  const [prevValueForAnalytics, setPrevValueForAnalytics] = React.useState(
    quoteContext.initialValues ? quoteContext.initialValues[props._key] : null,
  );
  const [clickTimestamp, setClickTimestamp] = React.useState(null);
  const prevValue = quoteContext.initialValues ? quoteContext.initialValues[props._key] : null;
  const currValue = values[props._key];
  const shouldDisplay = quoteContext.displayNotes && quoteContext.initialValues && prevValue !== currValue;
  const prevValueTitle = getValueTitleByAttributeKeyAndValue(
    props._key,
    prevValue,
    persephoneContext.catalog,
  );
  const productAlias = persephoneContext.catalog[
    camelCase(quoteContext.product.getProductKey())
  ].productAliases.find(a => a.key === quoteContext.alias);

  const debouncedClickField = React.useCallback(
    debounce((name, prevVal, newVal) => {
      clickField({ field_name: name, old_value: prevVal, new_value: newVal });
    }, 1000),
    [],
  );

  const getAttributeValues = (attributeValues: Value[]) =>
    attributeValues
      .map(v => {
        const override = productAlias?.nameOverrides.find(no => no.userInputValueSettingId === v.id);

        if (override) {
          return { ...v, name: override.name };
        }
        return v;
      })
      .filter(
        v =>
          !(
            v.hidden === true ||
            (!props.custom && v.key === 'custom') ||
            !!productAlias?.hiddenValues?.includes(v.id)
          ),
      );
  const attributeValues = getAttributeValues(props.values);
  const attributeValueGroups = props.valueGroups.map(vg => ({
    name: vg.name,
    values: getAttributeValues(vg.values),
  }));

  const controlType = getControlType(attributeValues, attributeValueGroups);

  const handleChange = e => {
    let newValue;
    if (controlType === 'select' || controlType === 'select-optgroup' || !!props.customDimension) {
      newValue = e.key;
    } else {
      newValue = e.target.value;
    }
    setFieldValue(props._key, newValue);
    debouncedClickField(props.name, prevValueForAnalytics, newValue);
    setPrevValueForAnalytics(newValue);
  };

  const handleTooltipClick = () => {
    const timestamp = Date.now();
    setClickTimestamp(timestamp);
  };

  const handleTooltipLeave = () => {
    if (clickTimestamp) {
      const duration = `${(Date.now() - clickTimestamp) / 1000} seconds`;
      clickTooltip({ duration, tooltip_name: tooltips[props.tooltip]?.title });
    }
  };

  return (
    <div className="mt-1">
      <FieldWrapper
        Input={
          (!!props.customDimension && (
            <CustomDimensionSelect
              _key={props._key}
              min={props.customDimensionRange[0]}
              max={props.customDimensionRange[1]}
              units={props.customDimensionFeet ? 'feet' : 'inches'}
              label={props.name}
              onChange={handleChange}
            />
          )) ||
          (controlType === 'input' && (
            <Field
              name={props._key}
              data-cy={`attribute-${props._key}`}
              component={Input}
              Tooltip={
                !!props.tooltip &&
                !props.hideTooltips && (
                  <Tooltip
                    position="top"
                    contentToolTip={tooltips[props.tooltip]?.content}
                    onClick={handleTooltipClick}
                    onLeave={handleTooltipLeave}
                  />
                )
              }
              label={props.name}
              wrapperClassName="mb-3"
              hideErrorMessage
              validate={props.validate}
              onChange={handleChange}
            />
          )) ||
          ((controlType === 'select' || controlType === 'select-optgroup') && (
            <Field
              data-cy={`attribute-${props._key}`}
              name={props._key}
              component={Combobox}
              label={props.name}
              optionGroups={attributeValueGroups}
              Tooltip={
                !!props.tooltip &&
                !props.hideTooltips && (
                  <Tooltip
                    position="top"
                    contentToolTip={tooltips[props.tooltip]?.content}
                    message={!!props.tooltip && tooltips[props.tooltip]?.title}
                    onClick={handleTooltipClick}
                    onLeave={handleTooltipLeave}
                  />
                )
              }
              options={attributeValues}
              wrapperClassName="mb-3"
              hideErrorMessage
              onChange={handleChange}
            />
          )) ||
          (controlType === 'radio-tabs' && (
            <FieldWrapper
              className="mb-3"
              Label={<Label placement="left">{props.name}</Label>}
              Tooltip={
                !!props.tooltip &&
                !props.hideTooltips && (
                  <Tooltip
                    position="top"
                    contentToolTip={tooltips[props.tooltip]?.content}
                    message={!!props.tooltip && tooltips[props.tooltip]?.title}
                    onClick={handleTooltipClick}
                    onLeave={handleTooltipLeave}
                  />
                )
              }
              Input={
                <RadioTabs hideErrorMessage>
                  <Field
                    name={props._key}
                    checked={values[props._key] === attributeValues[0].key}
                    value={attributeValues[0].key}
                    label={attributeValues[0].name}
                    component={RadioTab}
                    size="sm"
                    onChange={handleChange}
                  />
                  <Field
                    name={props._key}
                    checked={values[props._key] === attributeValues[1].key}
                    value={attributeValues[1].key}
                    label={attributeValues[1].name}
                    component={RadioTab}
                    size="sm"
                    onChange={handleChange}
                  />
                </RadioTabs>
              }
            />
          ))
        }
        Note={
          shouldDisplay && (
            <LinkLike
              color="black"
              underline="active"
              data-cy={`attributeBeforeBtn-${props._key}`}
              onClick={() => {
                setFieldValue(props._key, quoteContext.initialValues[props._key]);
              }}
            >{`before: ${prevValueTitle}`}</LinkLike>
          )
        }
      />
    </div>
  );
};

export default Attribute;
