import * as React from 'react';
import { contactPhone } from 'app/constants';
import Link from 'styleguide/components/Links/Link';

import numberOfPages from 'assets/images/tooltips/number_of_pages.png';
import divider from 'assets/images/tooltips/divider.png';
import fullBleed from 'assets/images/tooltips/full_bleed.png';
import collated from 'assets/images/tooltips/collated.png';
import dividerTabs from 'assets/images/tooltips/dividerTabs.png';
import tabName from 'assets/images/tooltips/tabName.png';
import tabPage from 'assets/images/tooltips/tabPage.png';

export default {
  'saddle-quantity-tooltip': {
    title: 'How To Determine Number of Pages',
    content: (
      <div>
        <h3 style={{ fontSize: '16px' }}>How To Determine Number of Pages</h3>
        <strong style={{ textDecoration: 'underline' }}>Books and Booklets</strong>
        <br />
        Count all pages in your finished product - including the front cover, inside front cover, back cover,
        and inside back cover.
        <img width="182" height="91" src={numberOfPages} alt="number of pages" />
        <p> This book is 8 pages, including covers. </p>
        <strong style={{ textDecoration: 'underline' }}>Still have questions?</strong>
        <p>- Please contact us. We are here to help! </p>
        <p>- {contactPhone}</p>
        <p>
          <Link to="/contact-us" color="blue" underline="always">
            - Email Us
          </Link>
        </p>
      </div>
    ),
  },
  'collation-tooltip-partial': {
    title: 'Difference between collated and uncoated',
    content: <img src={collated} width="300" height="300" alt="collated vs uncoated" />,
  },
  'color-copies-quantity-tooltip': {
    title: 'How To Determine Number of Pages',
    content: (
      <div>
        <h3 style={{ fontSize: '16px' }}>How To Determine Number of Pages</h3>
        <p>If your PDF, Word doc, etc. is 24 pages, simply enter &quot;24.&quot; Don&apos;t overthink it! </p>
        <strong style={{ textDecoration: 'underline' }}>Single-Sided</strong>
        <p>- If you order 24 pages, you&apos;ll receive 24 sheets</p>
        <p>- One side of the sheet is printed</p>
        <strong style={{ textDecoration: 'underline' }}>Double-Sided</strong>
        <p>- If you order 24 pages, you&apos;ll receive 12 sheets</p>
        <p>- Both sides of the sheet are printed</p>
        <strong style={{ textDecoration: 'underline' }}>Still have questions?</strong>
        <p>- Contact us right now! It is always better to do it right the first time.</p>
        <p>- {contactPhone}</p>
        <p>
          <Link to="/contact-us" color="blue" underline="always">
            - Email Us
          </Link>
        </p>
      </div>
    ),
  },
  'divider-sheets-tooltip-partial': {
    title: 'Where are divider sheets placed?',
    content: (
      <div>
        <h3 style={{ fontSize: '16px' }}>Divider Sheets</h3>
        <strong style={{ textDecoration: 'underline' }}>Where are divider sheets placed?</strong>
        <p>- A divider sheet is placed between each document/copy. </p>
        <p>- The number of documents/copies is selected in the</p>
        <p>&nbsp;&nbsp;&quot;How many copies would you like?&quot; section of the quoter</p>
        <p>
          -{' '}
          <i>
            Divider sheets will <strong style={{ textDecoration: 'underline' }}>NOT</strong> be put inside a
            document/copy,{' '}
          </i>
        </p>
        <p>&nbsp;&nbsp;they will only be put between documents/copies.</p>
        <br />
        <img src={divider} width="300" height="305" alt="divider" />
      </div>
    ),
  },
  'full-bleed-tooltip-partial': {
    title: 'How to determine full bleed',
    content: (
      <div>
        <h3 style={{ fontSize: '16px' }}>Full Bleed</h3>
        <strong style={{ textDecoration: 'underline' }}>How to determine full bleed</strong>
        <p>If your design extends to any edge of the sheet,</p>
        <p>then you should select full bleed.</p>
        <br />
        <img
          width="419"
          height="95"
          className="examples-of-full-bleed-tooltip"
          src={fullBleed}
          alt="How to determine full bleed"
        />
      </div>
    ),
  },
  'mailing-services-tooltip-partial': {
    title: 'Mailing Services',
    content: (
      <div>
        <h3 style={{ fontSize: '16px' }}>Mailing Services</h3>
        <p>Our mailing service ensures you get the largest</p>
        <p>discount from the post office. We take the hassle</p>
        <p>out of preparing your mail for delivery.</p>
        <br />
        <strong style={{ textDecoration: 'underline' }}>What does the Mailing Service Include?</strong>
        <p>- Address Verification</p>
        <p>- Addressing and Sorting</p>
        <p>- Paperwork and Delivery</p>
        <br />
        <strong style={{ textDecoration: 'underline' }}>Still have a question?</strong>
        <p>Please contact us. We&apos;re here to help! </p>
        <p>Call us at 1-858-800-2889</p>
        <p>
          Email us at{' '}
          <a href="mailto:postcardmailing@mgxcopy.com?Subject=Postcard%20Mailing%20and%20Printing">
            postcardmailing@mgxcopy.com
          </a>
        </p>
      </div>
    ),
  },
  'paper-type-tooltip-partial': {
    title: 'Paper Info',
    content: (
      <div>
        <h3 style={{ fontSize: '16px' }}>Paper Info</h3>
        <strong style={{ textDecoration: 'underline' }}>Standard Uncoated</strong>
        <p>- 50 lb. text ~ 20 lb. bond, or copy paper</p>
        <p>- 60 lb. text ~ 24 lb. bond</p>
        <p>- 70 lb. text ~ 28 lb. bond</p>
        <strong style={{ textDecoration: 'underline' }}>Gloss / Matte Coated Text</strong>
        <p>- Comparable in thickness to standard paper</p>
        <p>- High-quality finish &amp; coating</p>
        <p>- Used for magazines pages, brochures, flyers</p>
        <p>- 60lb Gloss gives an ultra-thin magazine feel</p>
        <strong style={{ textDecoration: 'underline' }}>Card Stock / Cover Stock</strong>
        <p>
          {' '}
          -{' '}
          <span style={{ fontStyle: 'italic', color: '#000' }}>
            80 lb. cover is substantially thicker than 80 lb. text
          </span>
        </p>
        <p>- High-quality finish &amp; coating</p>
        <p>- Often used for book covers, posters, thicker flyers</p>
        <strong style={{ textDecoration: 'underline' }}>Tear resistant</strong>
        <p>
          {' '}
          - <span style={{ fontStyle: 'italic', color: '#000' }}>Water resistant, Tear resistant. </span>
        </p>
        <p>- Resistant to tearing and spills</p>
      </div>
    ),
  },
  'uv-tooltip-partial': {
    title: 'UV ',
    content: (
      <div>
        <label>
          UV coating&nbsp;-&nbsp;
          <span className="uv-tooltip quoter-tooltip" style={{ color: '#ff4500' }}>
            Recommended!
          </span>
        </label>
      </div>
    ),
  },
  'tab-dividers-tooltip': {
    title: 'help',
    content: (
      <div>
        <label>
          <h3 style={{ fontSize: '16px' }}>Tab Dividers</h3>
          <p>
            Divider Tabs allow you to quickly navigate through your document. You can either choose printing
            extended part of the tab alone. Or, you can choose to extend your design to print on the full
            divider page.
          </p>
          <img width="527" height="288" src={dividerTabs} alt="divider Tabs" />
        </label>
      </div>
    ),
  },
  'tab-name-tooltip': {
    title: 'help',
    content: (
      <div>
        <label>
          <h3 style={{ fontSize: '16px' }}>Tab Text</h3>
          <p>We&apos;ll add text to the tab extension for you - what do you want it to say?</p>
          <img width="484" height="511" src={tabName} alt="Tab Name" />
        </label>
      </div>
    ),
  },
  'tab-page-tooltip': {
    title: 'tab page',
    content: (
      <div>
        <label>
          <h3 style={{ fontSize: '16px' }}>Tab Page Location</h3>
          <p>Tell us where the Divider Tab should be located within your digital file.</p>
          <img width="479" height="344" src={tabPage} alt="Tab Page" />
        </label>
      </div>
    ),
  },
  'binder-inserts-tooltip': {
    title: 'binder inserts',
    content: (
      <div>
        <label>
          <h3 style={{ fontSize: '16px' }}>Binder Inserts</h3>
          <p>
            Our binders have clear plastic pockets on the outside that allow you to insert a sheet to create a
            custom exterior. We can provide these cover sheets for the pocket on the front cover, back cover,
            and spine of the binder.
          </p>
        </label>
      </div>
    ),
  },
  'assembly-tooltip': {
    title: 'assembly',
    content: (
      <div>
        <label>
          <h3 style={{ fontSize: '16px' }}>Assembly</h3>
          <p>
            If we&apos;re printing covers for you, we can also insert them into the binder on your behalf,
            saving you the trouble. Or, if you&apos;d prefer, we can bundle them separately and ship them with
            your binders.
          </p>
        </label>
      </div>
    ),
  },
  'binder-size-tooltip': {
    title: 'binder size',
    content: (
      <div>
        <label>
          <h3 style={{ fontSize: '16px' }}>Binder Width</h3>
          <p>
            Binder width is measured by the diameter of the metal ring within the binder. If you have a
            specific document that you need to fit within your binder, and aren&apos;t sure how to calculate
            the width, please contact our Customer Service team for assistance.
          </p>
        </label>
      </div>
    ),
  },
};
