import * as React from 'react';
import cn from 'classnames';
import { IconTooltipInfo, IconTooltipQuestion } from 'styleguide/icons';
import { UiColor } from 'styleguide/styles/colors';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';

export type Position =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end';

export type TooltipColor = 'light' | 'blue' | 'dark';

interface Props extends React.HTMLAttributes<HTMLElement> {
  type?: 'question' | 'info' | 'text';
  position?: Position;
  message?: string;
  contentToolTip?: string | JSX.Element;
  onClick?: () => void;
  onLeave?: () => void;
  iconColor?: UiColor | 'dark';
  filled?: boolean;
  color?: TooltipColor | 'blue';
  withoutArrow?: boolean;
  bodyClassName?: string;
  iconClassName?: string;
}

const Tooltip = ({ color = 'light', className, type = 'question', onClick, onLeave, ...props }: Props) => {
  const arrowStyles = {
    top: cn(
      `after:border-l-[12px] after:border-l-transparent after:border-t-[12px] after:border-t-shades-0 
      after:border-r-[12px] after:border-r-transparent`,
      `after:-left-[12px] after:bottom-[2px]`,
      `-bottom-[12px] border-t-shades-0 border-t-[12px] border-r-[12px] border-l-[12px] border-b-0`,
      `${props.filled ? 'after:border-l-0 after:border-r-0' : ''}`,
      `${color === 'blue' ? '!border-t-blue' : ''}`,
      `${color === 'dark' ? '!border-t-default' : ''}`,
    ),
    bottom: cn(
      `after:border-l-[12px] after:border-l-transparent after:border-b-[12px] after:border-b-shades-0 after:border-r-[12px] after:border-r-transparent`,
      `after:-left-[12px] after:top-[2px]`,
      `-top-[12px] border-t-shades-0 border-b-[12px] border-r-[12px] border-l-[12px] border-t-0`,
      `${props.filled ? 'after:border-l-0 after:border-r-0' : ''}`,
      `${color === 'blue' ? '!border-b-blue' : ''}`,
      `${color === 'dark' ? '!border-b-default' : ''}`,
    ),
    left: cn(
      `after:border-l-[10px] after:border-l-shades-0 
    after:border-t-[10px] after:border-t-transparent after:border-b-[12px] after:border-b-transparent`,
      `after:-left-[12px] after:-bottom-[12px]`,
      `-right-[12px] border-t-shades-0 border-l-[12px] border-t-[12px] border-b-[14px] border-r-0`,
      `${props.filled ? 'after:border-t-0 after:border-b-0' : ''}`,
      `${color === 'blue' ? '!border-l-blue' : ''}`,
      `${color === 'dark' ? '!border-l-default' : ''}`,
    ),
    right: cn(
      `after:border-r-[12px] after:border-r-shades-0
    after:border-t-[12px] after:border-t-transparent after:border-b-[12px] after:border-b-transparent`,
      `after:-top-[12px] after:-right-[14px]`,
      `-left-[12px] border-t-shades-0 border-r-[14px] border-t-[12px] border-b-[12px] border-l-0`,
      `${props.filled ? 'after:border-t-0 after:border-b-0' : ''}`,
      `${color === 'blue' ? '!border-r-blue' : ''}`,
      `${color === 'dark' ? '!border-r-default' : ''}`,
    ),
  };

  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.position || 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [4, 9] } },
    ],
  });
  const placement = attributes && attributes.popper && attributes.popper['data-popper-placement'];

  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className={cn('flex', className)} onClick={onClick}>
        <div className={cn('flex', props.iconClassName)}>
          {type === 'question' && (
            <IconTooltipQuestion color={props.iconColor} size="sm" fill={props.filled ? 'filled' : 'none'} />
          )}
          {type === 'info' && (
            <IconTooltipInfo color={props.iconColor} size="sm" fill={props.filled ? 'filled' : 'none'} />
          )}
          {type === 'text' && (
            <div className="text-xs font-hvBold cursor-pointer">{props.message || 'i'}</div>
          )}
        </div>
      </Popover.Button>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        afterEnter={onClick}
        afterLeave={onLeave}
      >
        <Popover.Panel
          className={cn(
            'z-100 sm:w-max max-w-[500px] rounded-lg bg-shades-0 px-5 py-4 text-sm shadow-xl',
            props.filled ? 'text-shades-0' : 'text-default',
            props.filled && color === 'blue' ? 'bg-blue' : '',
            props.filled && color === 'dark' ? 'bg-default' : '',
            !props.filled && color === 'blue' ? 'border-2 border-solid border-blue' : '',
            !props.filled && color === 'dark' ? 'border-2 border-solid border-default' : '',
            props.bodyClassName,
          )}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {props.contentToolTip}
          <div
            className={cn(
              `after:absolute after:block after:border-solid after:border-transparent after:bg-transparent after:content-['']`,
              'absolute h-0 w-0 border-solid border-transparent',
              placement && placement.startsWith('bottom') && arrowStyles.bottom,
              placement && placement.startsWith('top') && arrowStyles.top,
              placement && placement.startsWith('left') && arrowStyles.left,
              placement && placement.startsWith('right') && arrowStyles.right,
            )}
            ref={setArrowElement}
            style={styles.arrow}
            {...attributes.arrow}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Tooltip;
